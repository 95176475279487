/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button, SeparateLine } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Chci prodat"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--80 pt--80" style={{"backgroundColor":"#f5f8fd"}} name={"ocpl270ihkd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Chci prodat\n\n"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1070,"marginTop":22}} content={"Určíme správnou tržní cenu a připravíme vaši nemovitost, abychom ji mohli ukázat v její nejlepší podobě a potenciálu. Díky osvědčeným marketingovým nástrojům, které používáme, oslovíme široké spektrum potenciálních kupců, což zvýší šanci na rychlý prodej vaší nemovitosti za optimální cenu. Vaše spokojenost s rychlostí a výsledkem prodeje je naším společným cílem.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"3l2zi8a56dj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" style={{"paddingLeft":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":596}}>
              
              <Title className="title-box fs--30" content={"Proč se mnou ?\n\n\n"}>
              </Title>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Má osmiletá zkušenost s vyřizováním hypoték mi poskytla hluboké porozumění pro potřeby a výzvy jak prodávajících, tak kupujících. Tato zkušenost mi umožňuje efektivně a empaticky komunikovat s všemi zúčastněnými stranami, což je základem pro hladký průběh prodeje vaší nemovitosti.\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={" Přizpůsobím se vašim specifickým potřebám a společně vypracujeme strategii prodeje, která bude nejlépe odpovídat vašim cílům. Ať už potřebujete prodat rychle, nebo máte čas na to, aby se našel ten pravý kupující, vždy vás vyslechnu, navrhnu plán a probereme všechny důležité detaily. Mým hlavním cílem je oslovit širokou škálu potenciálních zájemců a zajistit prodej za optimální cenu. Celý proces zjednoduším na minimum, abyste měli co nejméně starostí, a veškerou složitou práci necháte na mě.\n\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Co mě odlišuje? Mé hluboké porozumění pro obě strany trhu - jak pro prodávající, tak pro kupující, získané při vyřizování úvěrů na bydlení. Tato unikátní perspektiva mi umožňuje lépe předvídat potenciální nástrahy, vyvarovat se jim a zajistit tak všem zúčastněným stranám co nejhladší průběh transakce. Mé zkušenosti a znalosti jsou vaším zárukou, že se budete moci spolehnout na profesionální vedení celým procesem s minimálními komplikacemi.\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape3" action={"contact"} name={"9velcwwvqg"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"ndsbszx345p"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"hx6mo2bteq"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"mbhc4li3ha"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"uudb3rmixl8"},{"name":"Odeslat","type":"submit","id":"chakkgeyurq"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c4hlub css-c2cxkz --parallax pb--60 pt--60" name={"prefooter"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=3000x_.png);
    }
  
background-position: 50% 25%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 fs--48 w--600 lh--14 pb--06 pt--06" style={{"maxWidth":824}} content={"<span style=\"color: var(--color-custom-2);\">Stavíme mosty k vašim vysněným nemovitostem\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4" style={{"marginTop":0}} content={"Kontaktovat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k57myfoevo"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}